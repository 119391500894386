import React from 'react';

function DownloadButton(props) {
    const onButtonClick = () => {
        if(props?.filename) {
            // using Java Script method to get PDF file
            fetch(props.filename).then((response) => {
                response.blob().then((blob) => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = props.filename;
                    alink.click();
                });
            });
        }
    };

    return (
        <button onClick={onButtonClick}>{props.text}</button>
    );
}

export default DownloadButton;
