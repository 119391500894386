import React from 'react';
import DownloadButton from '../../components/DownloadButton';
import Slideshow from '../../components/Slideshow';
import { generateImageList, shuffleList } from '../../utils';

// Change the path here to select a different image folder location for the slideshow
const imageList = generateImageList(require.context('../../images/swordschool', false));

const graysLake = {
    latitude: '41.570047',
    longitude: '-93.631776',
    description: 'The back field at Grays Lake Iowa, 401 NE 66th Ave, Des Moines, IA 50313.',
};

const goodrell = {
    latitude: '41.624429',
    longitude: '-93.562609',
    description: 'A cafeteria at Goodrell Middle School, 3300 E 29th St., Des Moines, IA 50317.',
};

const calculateSeason = () => {
    const currentMonth = new Date().getMonth();
    switch (currentMonth) {
    case 0:
    case 1:
    case 2:
    case 3:
    case 8:
    case 9:
    case 10:
    case 11:
        return 'winter';
    case 4:
    case 5:
    case 6:
    case 7:
        return 'summer';
    default:
        return 'err';
    }
};

const showInMap = () => {
    const season = calculateSeason();
    if (season === 'winter') {
        window.open('https://maps.google.com?q='+goodrell.latitude+','+goodrell.longitude);
    } else if (season === 'summer') {
        window.open('https://maps.google.com?q='+graysLake.latitude+','+graysLake.longitude);
    } else {
        console.error('something\'s wrong with the dates');
    }
};

const getCurrentLocation = () => {
    const season = calculateSeason();
    if (season === 'winter') {
        return goodrell.description;
    } else if (season === 'summer') {
        return graysLake.description;
    } else {
        return '';
    }
};

export default function SwordSchool() {
    shuffleList(imageList);
    return (
        <>
            <div className='quick-links'>
                <a href='#when-and-where'>When &amp; Where</a> |
                <a href='#weather-policy'>Weather Policy</a> |
                <a href='#instructors'>Instructors</a> |
                <a href='#resources'>Resources</a>
            </div>
            <div className='page-content remove-bottom-margin'>
                <h1>Sword School</h1>
                <h2>What Do We Teach?</h2>
                <p>
                    The Band of Iron Lions specializes in HEMA (Historical European Martial Arts), with a primary focus on training aspiring students in the art of the longsword.
                    This iconic two-handed sword features a lengthy blade, a slender tip, and a broad crossguard, offering an exceptional balance between cutting, thrusting, and defensive capabilities.
                    Rooted in the teachings of Johannes Liechtenauer, a distinguished German master of the 14th century whose wisdom resonated throughout the Medieval era, our curriculum draws from surviving manuscripts.
                    Graduates of our course not only master the longsword but also have the opportunity to explore a diverse array of weapon systems, including arming swords, shields, daggers, rapiers, sabers, and more.
                </p>
                <h2>Who Can Participate?</h2>
                <p>
                    We provide class space for students aged 16 and older, with exceptions considered on a case-by-case basis.
                    We warmly welcome both aspiring and seasoned fencing enthusiasts with a keen interest in delving into the world of Medieval martial arts.
                    Prospective students are encouraged to try our classes before committing to purchasing equipment.
                    Newcomers will receive one month of complimentary tuition and access to group equipment to kickstart their journey.
                    So, there&apos;s no need to worry about bringing anything except a readiness to learn!
                </p>
            </div>
            <Slideshow imageset={imageList}/>
            <div className='page-content'>
                <h2 id='when-and-where'>When and Where?</h2>
                <p>
                    We conduct practices every Tuesday and Thursday, from 6:30 p.m. to 8:30 p.m. Throughout the fall, winter, and early spring (September - April), our sessions are held indoors.
                    During the late spring and summer months (May - August), we practice at a designated outdoor location.
                    This setup enables us to maintain consistent training year-round.
                    {/*<br/><br/>*/}
                    {/*Indoor session (Sep - Apr): Camp Sunnyside Easterseals Gymnasium, 401 NE 66th Ave, Des Moines, IA 50313*/}
                    {/*<br/><br/>*/}
                    {/*Outdoor Session (May - Aug): Gray’s Lake Park South East Lawn; John R Grubb Ln, Des Moines, IA, 50315*/}
                    <br/><br/>
                    Our current location is: {getCurrentLocation()}
                </p>     
                <button onClick={showInMap}>Click here to see in Google Maps</button>
                <h2>Safety</h2>
                <p>
                    At the Band of Iron Lions, we prioritize both enjoyable learning experiences and the safety of all participants.
                    We take pride in our disciplined approach to sparring, ensuring that it remains controlled and safe for everyone involved.
                    Full-power sparring is prohibited, and individuals unable to maintain control will be promptly removed.
                    All sparring members must adhere to wearing approved helmets and gloves throughout their sessions.
                    We offer helmets in various sizes and provide approved synthetic sparring gloves.
                    For those who have yet to complete the course, the use of synthetic sword trainers is mandatory.
                    These nylon sword trainers feature thick, rounded edges to minimize striking force and enhanced flexibility in thrusts, reducing the risk of serious injury.
                </p>
                <h2 id='weather-policy'>Weather Cancellation Policy</h2>
                <p>
                    Please be aware that class schedules are subject to change due to various events, holidays, and occasionally, last-minute adjustments due to weather conditions.
                    The schedule outlined above serves as a general guideline and may not be guaranteed.
                    We strongly advise all students to check our Discord and social media channels before heading to the school on any given day, as emergency cancellations or changes may occur.
                    <br/>
                    In order to prioritize the health and safety of our members, The Band of Iron Lions retains the right to cancel practice under the following circumstances:
                </p>
                <div className='centered-bullet-list'>
                    <ol>
                        <li>A Heat Advisory, Excessive Heat Watch, or Excessive Heat Warning has been issued for the practice area, and there is no indoor alternative available.</li>
                        <li>Rain is forecasted during the scheduled practice time, and there is no indoor practice space.</li>
                        <li>A Wind Chill, Blizzard, or Winter Storm Warning has been issued for the practice area.</li>
                        <li>Tornadoes, Floods, or any other acts of nature pose active threats in the practice area.</li>
                    </ol>
                </div>
                <p>
                    We consistently monitor weather conditions leading up to scheduled practice times and commit to providing updates on the practice status no later than 2 hours prior to the scheduled start.
                    If anyone feels uneasy about driving to or participating in practice due to weather concerns, we urge them to prioritize their own health and safety and opt-out from attending.
                    The well-being of our members is paramount, and we do not wish to pressure anyone into participating if they feel unsafe.
                </p>
                <h2 id='instructors' className='remove-bottom-margin'>Our Instructors</h2>
                <div className='centered-bullet-list'>
                    <ul>
                        <li>Michael Potter (Lead Instructor)</li>
                        <li>Daniel Froit (Instructor)</li>
                        <li>Adam Rotondi (Instructor)</li>
                    </ul>
                </div>
                <h2 id='resources' className='remove-bottom-margin'>Resources</h2>
                <div className='centered-bullet-list'>
                    <ul>
                        <li>Syllabus: <DownloadButton text='Download' filename='/files/LongswordSyllabus.pdf'/></li>
                    </ul>
                </div>
            </div>
        </>
    );
}
